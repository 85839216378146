.login_box__style {
  margin: 0 auto;
  margin-top: 25vh;
  /* background-color: rgba(255, 255, 255, 0.918); */
}
.text__style {
  margin: 0 auto;
  font-weight: 300;
  font-size: 1.5em;
  color: #040404;
}
.rememberMe__style {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}
.rememberMe__style > input {
  width: 2ch;
  margin-left: 6vw;
}
.facebook__style,
.google__style {
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #3c6acc;
}
.facebook__style {
  color: #4267b2;
  padding: 5px 0;
}
.facebook__style > img {
  width: 30px;
  padding: 5px;
}
.facebook__style > span {
  width: 100%;
  padding: 10px 0;
}
.google__style {
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: #0000008f;
}
.google__style > span {
  width: 100%;
  padding: 15px 0;
}
.google{
  width: 330px;
  margin-left: 200px;
}
