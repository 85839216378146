.modal_style{
  position: fixed; 
  z-index: 2; 
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.7); 
}