.nav__size {
  width: 100%;
  list-style-type: none;
  height: 60px;
}
.nav__bcc {
  position: relative;
  box-shadow: 0 0 25px 0 black;
}
.logo__size {
  width: 110px;
  height: 50px;
}
.hover__text {
  z-index: 100;
  margin-top: 1vh;
  border-radius: 5px;
  padding: 1ch;
  background-color: white;
  position: absolute;
  color: Black;
}
.svg_style {
  height: 40px;
}
.svg_style:hover {
  background-color: rgb(46, 44, 44);
  border-radius: 5px;
  border-bottom: 4px solid #4b4783;
}
.my_profile__style {
  padding-top: 10px;
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.my_profile__style > span {
  color: #f44336;
  font-size: 16px;
}

nav {
  display: grid;
  grid-template-columns: 30% 5% 25% auto;
  align-items: center;
  box-sizing: border-box;
}
.nav_flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 15px;
}
.nav_links {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  list-style-type: none;
  height: 40px;
}
.link_style {
  display: inline;
  cursor: pointer;
}
.link_style > a {
  display: inline-block;
  text-align: center;
  color: #df0000;
  font-size: 2ch;
  width: 100%;
  text-decoration: none;
  font-weight: bold;
}

#container {
  display: none;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

@media only screen and (max-width: 1400px) {
  .nav {
    grid-template-columns: 30% 10% 5% auto;
  }

  .link_style > a {
    width: 100%;
    padding: 0.5ch 0;
    font-size: 1.5ch;
  }
}
@media only screen and (max-width: 1024px) {
  nav {
    grid-template-columns: 25% 5% 0% auto;
  }
}

@media only screen and (max-width: 600px) {
  .center_burger_link {
    margin: 0 auto;
  }
  .nav_links {
    display: none;
  }
  .logo__size {
    width: 90%;
  }
  .link_style {
    text-align: center;
    width: 100%;
  }
  .link_style > a {
    width: 35vw;
    padding: 1ch 2ch;
    font-size: 1.5ch;
  }
  #container {
    display: block;
    width: 10%;
    margin-left: 70%;
  }
}
