.general_style_option_buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.name_input_of_saved {
  width: 180px;
  padding: 3px;
}
.load_presets {
  display: flex;
  z-index: 10001;
  flex-direction: column;
  background-color: rgb(15, 19, 39);
}
.preset__general_style {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}

.edit_block_style {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.preset_name {
  width: 100%;
}
.hover_style {
  left: 0;
  top: 0;
  display: block;
  min-width: 150px;
  padding: 5px;
  width: auto;
  position: absolute;
  background-color: black;
  border-radius: 5px;
}
