.die_button_style {
  font-size: 18px;
  border: 1px solid rgb(114, 111, 111);
  /* background-color: rgb(155, 152, 152); */
  border-radius: 5px;
  text-align: center;
  padding: 5px;
}

.die_span__button {
  all: unset;
  width: 100%;
  height: 100%;
  cursor: pointer;
}