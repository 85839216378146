$containerWidth: 45px;
$containerHeight: $containerWidth;

$faceWidth: $containerWidth * 0.5;
$faceHeight: $faceWidth * 0.86;

$transitionDuration: 0.5s;
$animationDuration: 3s;

$angle: 53deg;
$ringAngle: -11deg;
$sideAngle: 360deg/5;
$opacity: 0.75;
$color: rgba(255, 0, 0, $opacity);

$rotateX: -$angle;
$translateZ: $faceWidth * 0.335;
$translateY: -$faceHeight * 0.15;
$translateRingZ: $faceWidth * 0.75;
$translateRingY: $faceHeight * 0.78 + $translateY;
$translateLowerZ: $translateZ;
$translateLowerY: $faceHeight * 0.78 + $translateRingY;

.content {
  margin: auto auto;
  position: relative;
  width: $containerWidth;
  height: $containerHeight;
  perspective: 1500px;
  
}

.die {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform $transitionDuration ease-out;
  cursor: pointer;
  transform: rotateY($rotateX);
  &.rolling {
    animation: roll $animationDuration linear;
  }
  -webkit-animation: rotating 7s linear infinite;
  -moz-animation: rotating 7s linear infinite;
  -ms-animation: rotating 7s linear infinite;
  -o-animation: rotating 7s linear infinite;
  animation: rotating 7s linear infinite;

  

  .face {
    $horizontalMargin: -$faceWidth * 0.5;
    position: absolute;
    left: 50%;
    top: 0;
    margin: 0 $horizontalMargin;
    border-left: $faceWidth * 0.5 solid transparent;
    border-right: $faceWidth * 0.5 solid transparent;
    border-bottom: $faceHeight solid $color;
    width: 0px;
    height: 0px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    counter-increment: steps 1;

    &:before {
      content: counter(steps);
      position: absolute;
      top: $faceHeight * 0.25;
      left: -$faceWidth;
      color: #fff;
      text-shadow: 1px 1px 3px #000;
      font-size: $faceHeight * 0.5;
      text-align: center;
      line-height: $faceHeight * 0.9;
      width: $faceWidth * 2;
      height: $faceHeight;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        $angleMultiplier: $i - 1;
        transform: rotateY(-$sideAngle * $angleMultiplier)
          translateZ($translateZ)
          translateY($translateY)
          rotateX($angle);
      }
    }

    @for $i from 16 through 20 {
      &:nth-child(#{$i}) {
        $angleMultiplier: $i - 18;
        transform: rotateY($sideAngle * $angleMultiplier + $sideAngle/2)
          translateZ($translateLowerZ)
          translateY($translateLowerY)
          rotateZ(180deg)
          rotateX($angle);
      }
    }

    @for $i from 6 through 10 {
      &:nth-child(#{$i}) {
        $angleMultiplier: $i - 11;
        transform: rotateY(-$sideAngle * $angleMultiplier)
          translateZ($translateRingZ)
          translateY($translateRingY)
          rotateZ(180deg)
          rotateX($ringAngle);
      }
    }

    @for $i from 11 through 15 {
      &:nth-child(#{$i}) {
        $angleMultiplier: $i - 8;
        transform: rotateY($sideAngle * $angleMultiplier + $sideAngle/2)
          translateZ($translateRingZ)
          translateY($translateRingY)
          rotateX($ringAngle);
      }
    }
  }
}
/* Safari and Chrome */
@-webkit-keyframes rotating  {
  from {
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    -o-transform: rotate(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -ms-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}



