.burger__style {
  background-color: rgb(8, 8, 8);
  z-index: 3;
  width: 25%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 9vh;
  margin-left: 75%;
}
.close_btn__style {
  width: 100%;
  padding: 0.7ch 0ch;
  margin-left: auto;
  background-color: #f44336;
  border: none;
  border-radius: 3px;
  color: #fff;
}
