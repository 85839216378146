.error__style {
  position: fixed;
  z-index: 101;
  width: 35vw;
  height: 35vh;
  background-color: white;
  margin-left: 33%;
  margin-top: 18%;
  border: 1px solid black;
}
.error_top__style {
  background-color: #df0000;
  width: 100%;
  height: 1vh;
}
.image__style {
  margin-left: 14vw;
  margin-top: 1vh;
  width: 15%;
}
.title_error__style {
  width: 70%;
  text-align: center;
  color: #df0000;
  margin: auto;
  font-size: 30px;
  margin-top: 3vh;
}
.text_error__style {
  width: 70%;
  text-align: center;
  font-size: 18px;
  margin: auto;
}
