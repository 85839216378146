input {
  width: 100%;
  padding: 8px 1px;
  /* margin: 4px 20px; */
  font-size: 2ch;
  display: inline-block;
  border: 1px solid #3c6acc;
  border-radius: 4px;
  box-sizing: border-box;
}
input:focus {
  box-shadow: 0 0 20px #3c6acc;
  border-color: #3c6acc;
}
.error__style {
  font-size: 2ch;
  color: #df0000;
  margin: auto;
  text-align: center;
}
label {
  font-size: 2.2ch;
  font-weight: 300;
  margin-left: 2ch;
  color: #3c6acc;
}
.required_star__style {
  font-size: 22px;
  color: red;
}

.input_general__style {
  width: 30%;
  border-radius: 0;
  margin-inline: auto;
}
@media only screen and (max-width: 600px) {
  label {
    width: 100%;
    font-size: 2ch;
    margin-left: 6vw;
    color: #3c6acc;
  }

  .input_general__style {
    width: 90%;
    border-radius: 0;
    margin-inline: auto;
  }
}
