.news_box_bcc__color {
  color: black;
  filter: grayscale(50%);
  margin: 0 auto;
}

.news_box_size {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 500px;
  min-height: 200px;
  max-height: 550px;
  padding: 20px;
  border-radius: 5px;
}
.overflow {
  overflow: scroll;
  overflow-x: hidden;
}
.flex_nowrap {
  flex-wrap: nowrap;
}
@media only screen and (max-width: 600px) {
  .news_box_size {
    width: 80%;
    min-height: 25%;
    flex-wrap: nowrap;
  }
}
